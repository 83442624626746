import React from "react"
// import Button from "react-bootstrap/esm/Button"
import blink from "../../../src/images/Home/Strategy/blink.svg"
import "./home.css"

const GrowthAgency = ({ data }) => (
  <div className="home-growth-agency-content">
    <div className="container">
      <div className="home-growth-agency">
        <div className="home-growth-agency-left">
          <div className="home-growth-agency-blink">
            <img src={blink} alt="icon" />
            <p>{data?.tag_2}</p>
          </div>
          <h1 dangerouslySetInnerHTML={{ __html: data?.title_2 }}>
          </h1>
          {/* <div className="home-growbusiness-btn">
            <Button>Get Started</Button>
          </div> */}
        </div>
        <div className="home-growth-agency-right">
          <div className="home-growth-agency-grid">
            {data?.growth_points?.map((point) => (

              <div className="home-growth-agency-grid-box">
                <span>0{point?.point_no}</span>
                <h4>{point?.title}</h4>
                <p>
                  {point?.description}
                </p>
              </div>
            ))}
            {/* <div className="home-growth-agency-grid-box">
              <span>01</span>
              <h4>Proven Campaigns</h4>
              <p>
                Marketing campaigns that are proven to work for your industry
                based on experience
              </p>
            </div>
            <div className="home-growth-agency-grid-box">
              <span>02</span>
              <h4>Conversion Focused</h4>
              <p>
                User-friendly funnel design that is highly optimized for maximum
                conversions
              </p>
            </div>
            <div className="home-growth-agency-grid-box">
              <span>03</span>
              <h4>Fast Turnaround</h4>
              <p>
                Designed for a fast return on your investment so you can
                generate revenue quickly
              </p>
            </div>
            <div className="home-growth-agency-grid-box">
              <span>04</span>
              <h4>Real Results</h4>
              <p>
                Expertly crafted campaigns and sales funnels that drive results
                you can measure
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default GrowthAgency
