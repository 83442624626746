import React from "react"
import qoutes from "../../../src/images/Home/Homeourclients/qoutes.svg"
import profile1 from "../../../src/images/Home/Homeourclients/profile1.svg"
import profile2 from "../../../src/images/Home/Homeourclients/profile2.svg"
import profile3 from "../../../src/images/Home/Homeourclients/profile3.svg"
import "./home.css"
import { MediaHostUrl } from "../../config"

const OurClients = ({data}) => (
  <div className="home-our-client-content" id="casestudy">
    <div className="container">
      <div className="home-our-client">
        <h1 dangerouslySetInnerHTML={{__html:data?.title}}>
        </h1>
        <p dangerouslySetInnerHTML={{__html:data?.description}}>
        </p>
        <div className="home-our-client-grid">
          {data?.client_testimonials?.map((testimonial)=>(

          <div className="home-our-client-grid-box">
            <img src={qoutes} alt="qoutesicon" />
            <p>
              {testimonial?.testimonial}
            </p>
            <div className="our-client-profile">
              <img src={ testimonial?.client_image?.url} alt="icon" />
              <div className="our-client-profile-text">
                <h4>{testimonial?.name}</h4>
                <p>{testimonial?.occupation}</p>
              </div>
            </div>
          </div>
          ))}
          {/* <div className="home-our-client-grid-box">
            <img src={qoutes} alt="qoutesicon" />
            <p>
              "The team at Marketeq is amazing! They have helped us grow our
              business by generating more leads and sales. We are very happy
              with the results we've seen so far."
            </p>
            <div className="our-client-profile">
              <img src={profile1} alt="icon" />
              <div className="our-client-profile-text">
                <h4>Sharon Smith</h4>
                <p>Owner at NYC Salon</p>
              </div>
            </div>
          </div>

          <div className="home-our-client-grid-box">
            <img src={qoutes} alt="qoutesicon" />
            <p>
              "Since working with Marketeq, we have seen a significant increase
              in our lead generation and sales. We are very happy with their
              services and would recommend them to anyone looking to grow their
              business."
            </p>
            <div className="our-client-profile">
              <img src={profile2} alt="icon" />
              <div className="our-client-profile-text">
                <h4>John Belafort</h4>
                <p>Founder of Sports Haven</p>
              </div>
            </div>
          </div>

          <div className="home-our-client-grid-box">
            <img src={qoutes} alt="qoutesicon" />
            <p>
              "Marketeq was sent from heaven! We're up and runing faster than I thought possible, and now we even look professional."
            </p>
            <div className="our-client-profile">
              <img src={profile3} alt="icon" />
              <div className="our-client-profile-text">
                <h4>Charlene Davidson</h4>
                <p>Co-Founder at Bridge</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
)
export default OurClients
