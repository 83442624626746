import React from "react"
import difference from "../../../src/images/Home/Homedifferent/difference.svg"
import contract from "../../../src/images/Home/Homedifferent/contract.svg"
import approach from "../../../src/images/Home/Homedifferent/approach.svg"
import support from "../../../src/images/Home/Homedifferent/support.svg"
import "./home.css"

const MarketeqDifferent = ({ data }) => (
  <div className="home-marketeq-different-content">
    <div className="container">
      <div className="home-marketeq-different">
        <h1 dangerouslySetInnerHTML={{ __html: data?.title }}>
        </h1>
        <p dangerouslySetInnerHTML={{ __html: data?.description }}>
        </p>
        <div className="home-marketeq-different-item">
          <div className="home-marketeq-different-item-left">
            {
              data?.points?.map((point) => (
                <div className="home-marketeq-different-item-box">
                  <div className="home-marketeq-different-item-img">
                    <img loading="lazy" src={ point?.icon?.url} alt={point?.title} />
                  </div>
                  <div className="home-marketeq-different-item-text">
                    <h4>{point?.title}</h4>
                    <p>{point?.description}</p>
                  </div>
                </div>
              ))}
            {/* 
            <div className="home-marketeq-different-item-box">
              <div className="home-marketeq-different-item-img">
                <img src={approach} alt="approachimg" />
              </div>
              <div className="home-marketeq-different-item-text">
                <h4>Customized Approach</h4>
                <p>Tailored campaigns specifically for your business</p>
              </div>
            </div>

            <div className="home-marketeq-different-item-box">
              <div className="home-marketeq-different-item-img">
                <img src={support} alt="supportimg" />
              </div>
              <div className="home-marketeq-different-item-text">
                <h4>Top Quality Support</h4>
                <p>Best in class account management</p>
              </div>
            </div> */}
          </div>
          <div className="home-marketeq-different-item-right">
            <img src={data?.image?.url} alt="dif" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
export default MarketeqDifferent
