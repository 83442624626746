import { Link } from "gatsby"
import React from "react"
import aboutimg from "../../../src/images/Home/Aboutus/aboutdesktop.svg"
import "./home.css"
import { MediaHostUrl } from "../../config"

const AboutUs = ({ data }) =>
(
  <div className="home-aboutus-content" id="aboutus">
    <div className="container">
      <div className="home-aboutus">
        <div className="home-aboutus-left">
          <span>
            {data?.tag}
            {/* About us */}
          </span>
          <h2 dangerouslySetInnerHTML={{ __html: data?.title }}>
            {/* <strong>We’re on a mission</strong> to grow companies and accelerate
            business */}
          </h2>
          <p dangerouslySetInnerHTML={{ __html: data?.description }}>
            {/*             
            At Marketeq, we believe that every company has the potential to
            grow. That’s why our mission is to help businesses succeed in
            scaling their organization by building the systems they need for
            maximized growth.{" "} */}
          </p>
          <h3 dangerouslySetInnerHTML={{ __html: data?.bottom_title }}>
            {/* HIGH PERFORMANCE GROWTH <Link to="/">#HPG</Link> */}
          </h3>
        </div>
        <div className="home-aboutus-right">
          <img src={MediaHostUrl + data?.image?.url } alt="about-img" />
        </div>
      </div>
    </div>
  </div>
)

export default AboutUs
