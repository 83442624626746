import React from "react"
import compaign from "../../../src/images/Home/compaign/campaigns-desktop.svg"
import blink from "../../../src/images/Home/Strategy/blink.svg"
import "./home.css"
import { MediaHostUrl } from "../../config"

const TargetCompaign = ({data}) => (
  <div className="home-target-compaign-content" id="Campaigns">
    <div className="container">
      <div className="home-target-compaign">
        <div className="home-target-compaign-left">
          <img src={MediaHostUrl + data?.image?.url} alt="img" />
        </div>
        <div className="home-target-compaign-right">
          <div className="home-target-compaign-right-top">
            <span>{data?.tag}</span>
            <h2 dangerouslySetInnerHTML={{__html:data?.title_1}}>
            </h2>
            <p dangerouslySetInnerHTML={{__html:data?.description}}>
            </p>
          </div>
          <div className="home-compaign-item-box">
            {data?.campaign_points?.map((point)=>(

            <div className="home-target-compaign-item">
              <div className="home-compaign-blink">
                <img src={blink} alt="" />
                <span>{point?.title}</span>
              </div>
              <p>
                {point?.description}
              </p>
            </div>

            ))}
            {/* <div className="home-target-compaign-item">
              <div className="home-compaign-blink">
                <img src={blink} alt="" />
                <span>Targeted Audience</span>
              </div>
              <p>
                We target your ads to reach the ideal audience that is most
                likely to be interested in your product or service. This allows
                you to focus your marketing on a specific group of people that
                has the highest potential to convert into customers.
              </p>
            </div>

            <div className="home-target-compaign-item">
              <div className="home-compaign-blink">
                <img src={blink} alt="" />
                <span>Cost Effective</span>
              </div>
              <p>
                Our lead generation campaigns are a very cost-effective way to
                generate leads. We continually optimize your campaign to reduce
                your lead cost and increase your ROI so that you can scale your
                business quickly and efficiently.
              </p>
            </div>

            <div className="home-target-compaign-item">
              <div className="home-compaign-blink">
                <img src={blink} alt="" />
                <span>Measurable Results</span>
              </div>
              <p>
                You can see how many people click on your ad, how many of those
                people convert into leads, and how many of those leads buy your
                product or service. This allows us to continually optimize your
                campaigns to get the best results possible.
              </p>
            </div>
             */}
          </div>
        </div>
      </div>
    </div>
  </div>
)
export default TargetCompaign
