import React from "react"
import hand from "../../../src/images/Home/hand.svg"
import "./home.css"

const HomeBanner = ({data}) => (
  <div 
  className="home-banner-content">
    <div className="container">
      <div className="home-banner">
        <span>
          {/* Top rated digital agency */}
          {data?.tag}
          </span>
        <h1
         dangerouslySetInnerHTML={{__html:data?.title}} 
         >
            {/* {data?.title} */}
            {/* Scale your business */}
            {/* with proven digital marketing strategy */}
            {/* {data?.title_2} */}
        </h1>
        <div className="home-learn-more">
          <img src={hand} alt="icon" />
          <p>
            {" "}
            <span>Scroll down</span> to learn more about our services
          </p>
        </div>
      </div>
    </div>
  </div>
)
export default HomeBanner
