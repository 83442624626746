import React from "react"
import AboutUs from "../components/Home/Aboutus"
import GrowBusiness from "../components/Home/growbusiness"
import GrowYourBusiness from "../components/Home/Growbusinesslight"
import GrowthAgency from "../components/Home/Growthagency"
import HomeBanner from "../components/Home/home"
import HomeGrid from "../components/Home/homegrid"
import MarketeqDifferent from "../components/Home/Marketeqdifferent"
import MarketeqDigital from "../components/Home/marketeqdigital"
import OurClients from "../components/Home/Ourclients"
import OurPartner from "../components/Home/Ourpartner"
import DigitalStrategy from "../components/Home/Strategy"
import TargetCompaign from "../components/Home/Targetcompaign"
import Layout from "../components/Layout/layout"
// import GetStartedPage from "./get-started"

const IndexPage = ({ pageContext }) => {
  const { navBar, footer, homePage } = pageContext;
  console.log(pageContext);
  return (
    <Layout
      navBar={navBar}
      footer={footer}
    >
      <HomeBanner data={homePage?.banner} />
      <HomeGrid data={{ ...homePage?.grid, grid_cards: homePage?.grid_cards }} />
      <AboutUs data={homePage?.about_us} />
      <MarketeqDigital data={homePage?.marketeq_digital} />
      <DigitalStrategy data={homePage?.strategy} />
      <TargetCompaign data={homePage?.campaign} />
      <GrowBusiness data={homePage?.campaign} />
      <GrowthAgency data={homePage?.campaign} />
      <MarketeqDifferent data={homePage?.marketeq_different}/>
      <OurPartner data={homePage?.tech_partners}/>
      <OurClients data={homePage?.our_clients} />
      <GrowYourBusiness  data={homePage?.our_clients}/>
    </Layout>
  )
}

export default IndexPage
