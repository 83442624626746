import React from "react"
import "./home.css"

const MarketeqDigital = ({data}) => (
  <div className="home-digital-content">
    <div className="container">
      <div className="home-digital">
        <h1 dangerouslySetInnerHTML={{__html:data?.title}}>
          {/* <strong>Marketeq</strong> Digital */}

        </h1>
        <p dangerouslySetInnerHTML={{__html:data?.description}}>
        </p>
      </div>
    </div>
  </div>
)
export default MarketeqDigital
