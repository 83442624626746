import React from "react"

import facebook from "../../../src/images/Home/Homeourpartner/facebook.svg"
import meta from "../../../src/images/Home/Homeourpartner/meta.svg"
import googleads from "../../../src/images/Home/Homeourpartner/googlea.svg"
import youtube from "../../../src/images/Home/Homeourpartner/youtube.svg"
import marketing from "../../../src/images/Home/Homeourpartner/marketing.svg"
import googleanalytic from "../../../src/images/Home/Homeourpartner/googleanalytics.svg"
import tiktok from "../../../src/images/Home/Homeourpartner/tiktok.svg"
import funnels from "../../../src/images/Home/Homeourpartner/funnels.svg"

import "./home.css"
import { MediaHostUrl } from "../../config"

const OurPartner = ({ data }) => (
  <div className="home-our-partner-content">
    <div className="container">
      <div className="home-our-partner">
        <h1>{data?.title}</h1>
        <p>
          {data?.description}
        </p>

        <div className="home-our-partner-img">
          {
            data?.images?.map((_img) => (
              <img loading="lazy" src={MediaHostUrl + _img?.url} alt={_img?.name} />
            ))
          }
          {/* <img src={facebook} alt="facebook" />
          <img src={meta} alt="meta" />
          <img src={googleads} alt="googleads" />
          <img src={youtube} alt="youtube" />
          <img src={marketing} alt="marketing" />
          <img src={googleanalytic} alt="googleanalytic" />
          <img src={tiktok} alt="tiktok" />
          <img src={funnels} alt="funnels" /> */}
        </div>
      </div>
    </div>
  </div>
)
export default OurPartner
