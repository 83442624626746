import React from "react"
import Button from "react-bootstrap/esm/Button"
import strategyimg from "../../../src/images/Home/Strategy/leads-desktop.svg"
import blink from "../../../src/images/Home/Strategy/blink.svg"
import arrow from "../../../src/images/Home/Strategy/arrow.svg"
import "./home.css"
import { MediaHostUrl } from "../../config"

const DigitalStrategy = ({ data }) => {
  const handleScroll = id => {
    const position = document.getElementById(id)?.getBoundingClientRect()
    window.scrollTo({
      left: position?.left,
      top: position?.top + window.scrollY - 0,
      behavior: "smooth",
    })
  }
  return (
    <div className="home-strategy-content" id="strategy">
      <div className="container">
        <div className="home-strategy">
          <span>{data?.tag_1}</span>
          <h1 dangerouslySetInnerHTML={{__html:data?.title_1}}>
            {/* Highly optimized campaigns <span>Using human + AI algorithyms</span>{" "} */}
          </h1>
        </div>
        <div className="home-strategy-item">
          <div className="home-strategy-left">
            <img src={MediaHostUrl + data?.image?.url} alt="img" />
          </div>
          <div className="home-strategy-right">
            <div className="home-strategy-blink">
              <img src={blink} alt="blink-icon" />
              <span> {data?.tag_2}</span>
            </div>
            <h3 dangerouslySetInnerHTML={{__html:data?.title_2}}></h3>
            <p dangerouslySetInnerHTML={{__html:data?.description_2}}>
             
            </p>

            <Button onClick={() => window.location.href=data?.learn_more?.link}>
              {data?.learn_more?.text}
              <img src={arrow} alt="arrow" />
            </Button>
          </div>
        </div>
        <div className="home-strategy-growth">
          <span>{data?.tag_3}</span>
          <h3 dangerouslySetInnerHTML={{__html:data?.title_3}}>
            {/* Generate leads <br />
            <span>from multiple sources</span> */}
          </h3>
        </div>
      </div>
    </div>
  )
}
export default DigitalStrategy
