import React from "react"
// import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import google from "../../../src/images/Home/Homegrid/google.svg"
import facebook from "../../../src/images/Home/Homegrid/facebook.svg"
import instagram from "../../../src/images/Home/Homegrid/instagram-fill.svg"
import tiktok from "../../../src/images/Home/Homegrid/tiktok-icon.svg"
import linkedin from "../../../src/images/Home/Homegrid/linkedin-fill.svg"
import sale from "../../../src/images/Home/Homegrid/sale-funnel.svg"
import marketing from "../../../src/images/Home/Homegrid/marketing.svg"
import website from "../../../src/images/Home/Homegrid/custom-website.svg"
import seo from "../../../src/images/Home/Homegrid/seo.svg"
import "./home.css"
import { MediaHostUrl } from "../../config"

const HomeGrid = ({ data }) => (
  <div className="Home-grid-content">
    <div className="container">
      <div className="Home-grid-text">
        <h2 dangerouslySetInnerHTML={{ __html: data?.title }}>
          {/* See what <strong>we can do</strong> for you */}
        </h2>
        <p dangerouslySetInnerHTML={{ __html: data?.description }}>
          {/* Our marketing services include everything you need to generate the
          highest quality leads that have the best chance of converting into new
          customers. From targeted Facebook and Instagram campaigns, to
          pay-per-click lead generation on Google Ads, we'll help you reach your
          ideal customer base and scale your business.{" "} */}
        </p>
        <div className="Home-grid">

          {data?.grid_cards?.map((card) => (
            <div className="Home-grid-box">
              <div className="Home-grid-social-icon">
                <img src={MediaHostUrl + card?.icon?.url} alt="icon" />
              </div>
              <h3>{card?.title}</h3>
              <p>
                {card?.description}
              </p>

              <AnchorLink to={card?.learn_more?.link}>{card?.learn_more?.text}</AnchorLink>
            </div>
          ))}

        </div>
      </div>
    </div>
  </div>
)

export default HomeGrid



          // <div className="Home-grid-box">
          //   <div className="Home-grid-social-icon">
          //     <img src={google} alt="icon" />
          //   </div>
          //   <h3>Google Ads</h3>
          //   <p>
          //     Our PPC team will create, monitor, and optimize the performance of
          //     your paid marketing campaigns on Google Adwords.
          //   </p>

          //   <AnchorLink to="/ourservice/#googlebox">learn more</AnchorLink>
          //   {/* <Link to="/ourservice/#googlebox">learn more</Link> */}
          // </div>

          // <div className="Home-grid-box">
          //   <div className="Home-grid-social-icon">
          //     <img src={facebook} alt="icon" />
          //   </div>
          //   <h3>Facebook Ads</h3>
          //   <p>
          //     We'll create targeted ad campaigns that reach your ideal customer
          //     base on Facebook, ensuring you the highest quality leads.
          //   </p>
          //   {/* <Link to="/ourservice/#facebookads">learn more</Link> */}
          //   <AnchorLink to="/ourservice/#facebookads">learn more</AnchorLink>
          // </div>
          // <div className="Home-grid-box">
          //   <div className="Home-grid-social-icon">
          //     <img src={instagram} alt="icon" />
          //   </div>
          //   <h3>Instagram Ads</h3>
          //   <p>
          //     Our team of experts will design beautiful ad campaigns that drive
          //     results and help you reach your target audience on Instagram.
          //   </p>
          //   {/* <Link to="/ourservice/#instagramads">learn more</Link> */}
          //   <AnchorLink to="/ourservice/#instagramads">learn more</AnchorLink>
          // </div>
          // <div className="Home-grid-box">
          //   <div className="Home-grid-social-icon">
          //     <img src={tiktok} alt="icon" />
          //   </div>
          //   <h3>TikTok Ads</h3>
          //   <p>
          //     We help you reach a wider audience and generate high quality leads
          //     with creative and effective TikTok video campaigns.
          //   </p>
          //   <AnchorLink to="/ourservice/#tiktokads">learn more</AnchorLink>
          //   {/* <Link to="/">learn more</Link> */}
          // </div>
          // <div className="Home-grid-box">
          //   <div className="Home-grid-social-icon">
          //     <img src={linkedin} alt="icon" />
          //   </div>
          //   <h3>Linkedin Ads</h3>
          //   <p>
          //     We'll design and launch LinkedIn ad campaigns that help you
          //     connect with your target market by job title, company size, and
          //     other factors.
          //   </p>
          //   <AnchorLink to="/ourservice/#linkedinads">learn more</AnchorLink>
          //   {/* <Link to="/">learn more</Link> */}
          // </div>
          // <div className="Home-grid-box">
          //   <div className="Home-grid-social-icon">
          //     <img src={sale} alt="icon" />
          //   </div>
          //   <h3>Sales Funnels</h3>
          //   <p>
          //     We'll design and implement sales funnels that are optimized for
          //     conversions, so you can close more deals and grow your business
          //     faster.
          //   </p>
          //   <AnchorLink to="/ourservice/#customfunnels">learn more</AnchorLink>
          //   {/* <Link to="/">learn more</Link> */}
          // </div>
          // <div className="Home-grid-box">
          //   <div className="Home-grid-social-icon">
          //     <img src={marketing} alt="icon" />
          //   </div>
          //   <h3>Marketing Automation</h3>
          //   <p>
          //     We'll set up automated email and text campaigns, delivering timely
          //     and relevant messages to your leads and customers.
          //   </p>
          //   <AnchorLink to="/ourservice/#marketingautomation">
          //     learn more
          //   </AnchorLink>
          //   {/* <Link to="/">learn more</Link> */}
          // </div>
          // <div className="Home-grid-box">
          //   <div className="Home-grid-social-icon">
          //     <img src={website} alt="icon" />
          //   </div>
          //   <h3>Custom Web Design</h3>
          //   <p>
          //     Our web design specialists can create your custom websie that is
          //     user-friendly, easy to navigate, and optimized for conversions.
          //   </p>
          //   <AnchorLink to="/ourservice/#CustomWeb">learn more</AnchorLink>
          //   {/* <Link to="/">learn more</Link> */}
          // </div>
          // <div className="Home-grid-box">
          //   <div className="Home-grid-social-icon">
          //     <img src={seo} alt="icon" />
          //   </div>
          //   <h3>Search Engine Optimization (SEO)</h3>
          //   <p>
          //     Our SEO experts can build your domain authority so that your site
          //     gets top rankings on popular search engines like Google and Bing.
          //   </p>
          //   <AnchorLink to="/ourservice/#Seo">learn more</AnchorLink>
          //   {/* <Link to="/">learn more</Link> */}
          // </div>